export default {
  ADD_BET: 'ADD_BET',
  ADD_PRE_BET: 'ADD_PRE_BET',
  BET_PAYMENT_DELETE: 'BET_PAYMENT_DELETE',
  REMOVE_BETS: 'REMOVE_BETS',
  REMOVE_BET: 'REMOVE_BET',
  SET_CONFIG: 'SET_CONFIG',
  SET_ACTIVE_COMPONENT: 'SET_ACTIVE_COMPONENT',
  SET_ACTIVE_TICKET_TYPE: 'SET_ACTIVE_TICKET_TYPE',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_MIN_BET_PAYMENT: 'SET_MIN_BET_PAYMENT',
  SET_MIN_TICKET_PAYMENT: 'SET_MIN_TICKET_PAYMENT',
  SET_TOTAL_PAYMENT: 'SET_TOTAL_PAYMENT',
  SET_MIN_PAYMENT_ALLOWED: 'SET_MIN_PAYMENT_ALLOWED',
  SET_MAX_PAYMENT_ALLOWED: 'SET_MAX_PAYMENT_ALLOWED',
  SET_PAYMENT_PER_BET: 'SET_PAYMENT_PER_BET',
  SET_TICKET: 'SET_TICKET',
  SET_BET_PAYMENT: 'SET_BET_PAYMENT',
  SET_IS_VALID_BET: 'SET_IS_VALID_BET',
  SET_FUTURE_ROUNDS: 'SET_FUTURE_ROUNDS',
  SET_FUTURE_ROUNDS_PER_BET: 'SET_FUTURE_ROUNDS_PER_BET',
  SET_IS_MANUAL_MODE: 'SET_IS_MANUAL_MODE',
  SET_PLAYER_TICKETS: 'SET_PLAYER_TICKETS',
  SET_POSSIBLE_WIN: 'SET_POSSIBLE_WIN',
  SET_IS_PAYIN_IN_PROGRESS: 'SET_IS_PAYIN_IN_PROGRESS',
  SET_IS_LAST_TICKETS_IN_PROGRESS: 'SET_IS_LAST_TICKETS_IN_PROGRESS',
  SET_IS_QUICKPAY_ENABLED: 'SET_IS_QUICKPAY_ENABLED',
  SET_IS_PAYIN_BUTTON_DISABLED: 'SET_IS_PAYIN_BUTTON_DISABLED',
  SET_IS_PAYMENT_PER_BET_VALID: 'SET_IS_PAYMENT_PER_BET_VALID',
  SET_POSSIBLE_WIN_PRE_BET: 'SET_POSSIBLE_WIN_PRE_BET',
  SET_TOTAL_ODDS: 'SET_TOTAL_ODDS',
  SET_POSSIBLE_WIN_COMBO: 'SET_POSSIBLE_WIN_COMBO',
  SET_ACTIVE_BETSLIP_INPUT: 'SET_ACTIVE_BETSLIP_INPUT',
  SET_IS_STAKE_BOX_VISIBLE: 'SET_IS_STAKE_BOX_VISIBLE',
  SET_IS_BET_APPEAR_ANIMATION_DISABLED: 'SET_IS_BET_APPEAR_ANIMATION_DISABLED',
  SET_IS_TOTAL_PAYMENT_VALID: 'SET_IS_TOTAL_PAYMENT_VALID',
  SET_IS_PAYMENT_VALID: 'SET_IS_PAYMENT_VALID',
  SET_ROUND_NUMBER: 'SET_ROUND_NUMBER',
  SET_IS_CANT_SPLIT_ACTIVE: 'SET_IS_CANT_SPLIT_ACTIVE',
  SET_IS_USER_BALANCE_INVALID: 'SET_IS_USER_BALANCE_INVALID',
  SET_BETSLIP_BLOCKERS: 'SET_BETSLIP_BLOCKERS',
  SET_IS_FREE_BET_PAYIN_DISABLED: 'SET_IS_FREE_BET_PAYIN_DISABLED',
  UPDATE_BET: 'UPDATE_BET',
  UPDATE_SELECTED_BET: 'UPDATE_SELECTED_BET',
};
