<template>
  <div>
    <div
      class="betslipInfoBar"
      :class="{ betslipInfoBarCompact: !isExpanded }"
    >
      <div class="notification" v-if="isNotificationActive">
        <Notification v-if="!isExpanded" />
      </div>
      <div class="preBet" v-if="isPreBetActive">
        <div class="market">
          {{ this.preBet.bet.market }}
        </div>
        <div class="outcome">
          {{ getOutcome(this.preBet.bet) }}
        </div>
      </div>
      <div class="betsSelected" v-else-if="ticket?.length">
        {{ this.ticket.length }} {{ betsSelectedTranslation }}
      </div>
      <div v-else class="infoMessage">
        <i class="icon icon-info"></i>
          <span class="message">
            {{ this.translations.general_ticket_empty_message }}
          </span>
      </div>
      <div v-if="isExpanded">
        <Button label="" class="clearInfoBar" @change="clearBet()" :disabled="!isPreBetActive">
            <i class="icon icon-trash" />
        </Button>
        <Button
          @change="addToBetslip"
          :label="translations.general_add_bet"
          variation="brand"
          :disabled="isAddButtonDisabled || isFreeBetPayinDisabled"
          class="addToBetslip" />
      </div>
      <div v-if="!isExpanded && isPreBetActive">
          <i class="icon icon-close-a" @click="clearBet()" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Notification from '../../Notifications/Notification';
import Button from '../../Button';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipInfoBar',
  components: {
    Notification,
    Button,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('gamesBetslip', ['isPayinInProgress', 'preBet', 'ticket', 'isFreeBetPayinDisabled']),
    ...mapGetters(['translations']),
    ...mapGetters('notifications', ['isNotificationActive']),
    isPreBetActive() {
      return this.preBet.bet && this.preBet.bet.market;
    },
    isAddButtonDisabled() {
      return this.isPayinInProgress || !this.isPreBetActive;
    },
    betsSelectedTranslation() {
      return this.ticket.length > 1
        ? this.translations.general_multiple_bets_selected
        : this.translations.general_single_bet_selected;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['addBet', 'addPreBet', 'setPossibleWinPreBet']),
    addToBetslip() {
      this.addBet(this.preBet.bet);
    },
    clearBet() {
      this.addPreBet({});
      this.setPossibleWinPreBet({});
      eventBus.$emit('PrebetCleared');
      eventBus.$emit('formatBetslipInputValue');
    },
    getOutcome(bet) {
      return bet.odds ? `${bet.outcome} (${bet.odds.toFixed(2)})` : bet.outcome;
    },
  },
};
</script>

<style lang="scss" scoped>
.betslipInfoBarCompact {
  padding: 4px 9px;
}

.betslipInfoBar {
  background-color: var(--background-1);
  padding: 8px 0px 8px 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  font-size: 14px;
  color: var(--text-primary-1);

  .notification {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .preBet {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .market {
      font-size: 12px;
      color: var(--text-primary-2);
    }

    .outcome {
      color: var(--text-primary-1);
      font-size: 14px;
    }
  }

  .infoMessage {
    display: flex;
    align-items: center;
    color: var(--text-primary-2);

    .message {
      font-size: 12px;
    }

    .icon-info {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .addToBetslip {
    width: 184px;
    height: 40px;
  }

  .clearInfoBar {
    width: 60px;
    height: 40px;
    margin-right: 8px;
  }

  .icon-close-a {
    padding-right: 8px;
    font-size: 16px;
    cursor: pointer;
  }
}
</style>
