var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticket-history-wrapper" },
    [
      _vm.showModal
        ? _c("Modal", {
            ref: "modal",
            staticClass: "flex ticket-history-modal",
            attrs: {
              fullScreen: _vm.isMobile,
              showFooter: true,
              width: "800px",
              height: "500px",
            },
            on: {
              closeModal: _vm.closeModal,
              closeModalOutside: function ($event) {
                return _vm.closeModal("outside")
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex-center" }, [
                        _c(
                          "span",
                          { staticClass: "product-name" },
                          [
                            _c("LocalizedLabel", {
                              attrs: { translation: "gameTitle" },
                            }),
                          ],
                          1
                        ),
                        _c("i", {
                          staticClass: "icon icon-close-a",
                          on: { click: _vm.closeModal },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("TicketsHistory", {
                        attrs: {
                          locale: _vm.config.locale,
                          productNameLabel: _vm.translations["gameTitle"],
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              391377804
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }