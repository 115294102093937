<template>
  <div class="betslipHeader" v-swipeDown="closeBetslip">
    <div class="upperPart">
    <Tabs
      :tabs="components"
      :activeTab="activeComponent"
      @change="handleChange"
    />
    <button v-interactionStates
              class="betslip-minimize"
              @click="minimizeBetslip"
              v-if="isMinimizeVisible">
        <i class="icon icon-minimize" />
      </button>
    </div>
      <BetslipTicketTypes v-show="isTicketTypesComponentVisible" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Tabs from '../../Tabs';
import BetslipBody from './BetslipBody';
import BetslipHistory from './BetslipHistory';
import BetslipTicketTypes from './BetslipTicketTypes';
import swipeDown from '../../../directives/swipeDown';
import interactionStates from '../../../directives/interactionStates';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipHeader',
  components: {
    BetslipTicketTypes,
    Tabs,
  },
  data() {
    return {
      components: [],
    };
  },
  props: {
    isMinimizeVisible: {
      type: Boolean,
      default: false,
    },
    showLastTickets: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    swipeDown,
    interactionStates,
  },
  computed: {
    ...mapGetters(['translations', 'isDesktop']),
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('gamesBetslip', ['activeComponent', 'activeTicketTypes', 'isBetslipActive', 'numberOfBets', 'playerTickets']),
    hasMultipleTicketTypes() {
      return this.activeTicketTypes.length > 1;
    },
    isTicketTypesComponentVisible() {
      return this.isBetslipActive && this.hasMultipleTicketTypes;
    },
    numberOfLastTickets() {
      let count = 0;
      const accountedStatuses = ['OPEN', 'IN_PLAY', 'ACCEPTED'];

      this.playerTickets.forEach((ticket) => {
        if (accountedStatuses.includes(ticket.status.value)) count += 1;
      });
      return this.isLoggedIn ? count : 0;
    },

  },
  methods: {
    ...mapActions('gamesBetslip', ['setActiveComponent']),
    handleChange(value) {
      this.setActiveComponent(value);
    },
    closeBetslip() {
      eventBus.$emit('swipeDown');
    },
    handleComponents() {
      this.components.push({
        id: 0,
        key: 0,
        name: 'Betslip',
        label: this.translations.general_betslip,
        component: BetslipBody,
        disabled: false,
        counter: this.numberOfBets,
        isCounterActive: true,
        type: 'betslip',
      });
      if (this.showLastTickets) {
        this.components.push({
          id: 1,
          key: 1,
          name: 'TicketHistory',
          type: 'lastTickets',
          label: this.translations.general_active_tickets,
          component: BetslipHistory,
          disabled: !this.isLoggedIn,
          counter: this.numberOfLastTickets,
          isCounterActive: true,
          animate: true,
        });
      }
    },
    setBetslipAsActiveComponent() {
      if (this.activeComponent.name === 'TicketHistory') {
        this.setActiveComponent(this.components[0]);
      }
    },
    minimizeBetslip() {
      eventBus.$emit('MinimizeBetslip');
    },
  },
  watch: {
    isLoggedIn(value) {
      this.components[1].disabled = !value;

      if (!value) {
        this.setActiveComponent(this.components[0]);
      }
    },
    numberOfBets(newValue) {
      if (this.components[0]) this.components[0].counter = newValue;
    },
    numberOfLastTickets(newValue) {
      if (this.components[1]) this.components[1].counter = newValue;
    },
  },
  mounted() {
    this.handleComponents();
    this.setActiveComponent(this.components[0]);
    eventBus.$on('TicketRebet', this.setBetslipAsActiveComponent);
    eventBus.$on('betAddedToBetslip', this.setBetslipAsActiveComponent);
    eventBus.$on('SwitchToBetslipTab', this.setBetslipAsActiveComponent);
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    eventBus.$off('TicketRebet', this.setBetslipAsActiveComponent);
    eventBus.$off('betAddedToBetslip', this.setBetslipAsActiveComponent);
    eventBus.$off('SwitchToBetslipTab', this.setBetslipAsActiveComponent);
  },
  beforeDestroy() {
    eventBus.$off('TicketRebet', this.setBetslipAsActiveComponent);
    eventBus.$off('betAddedToBetslip', this.setBetslipAsActiveComponent);
    eventBus.$off('SwitchToBetslipTab', this.setBetslipAsActiveComponent);
  },
};
</script>

<style lang="scss" scoped>
.betslipHeader {
  .upperPart {
    display: flex;
    width: 100%;

    .betslip-minimize {
      font-size: 18px;
      color: var(--text-primary-1);
      border: 0;
      background-color: var(--card);
      padding: 0 8px;
      cursor: pointer;

      &::after {
        background-color: var(--card);
      }

      &:hover {
        background-color: var(--hover-state);
      }

      &:active {
        background-color: var(--active-state);
      }
    }
  }
}

::v-deep .tabs {
  width: 100%;
}

::v-deep .tabs .tab-button {
  max-width: 100%;
}
</style>
