import types from './mutationTypes';

export default {
  [types.UPDATE_USER](state, payload) {
    if (payload.auth) {
      state.user.auth = payload.auth; // { auth / profile }
    }
    if (payload.profile) {
      state.user.profile = payload.profile;
    }
  },
  [types.SET_USER_BALANCE](state, payload) {
    state.user.balance = payload;
  },
  [types.SET_TERMINAL_USER](state, payload) {
    state.isTerminalUser = payload;
  },
  [types.SET_USER_BONUSES](state, payload) {
    state.user.bonuses = payload;
  },
  [types.GIVE_THE_USER_FREE_BETS](state, freeBets) {
    state.user.freeBets = freeBets;
  },
  [types.SET_USER_SESSION_TOKEN](state, token) {
    state.user.sessionToken = token;
  },
};
