import axios from 'axios';
import gamesHeaders from './headers';

const http = axios.create({
  baseURL: process.env.VUE_APP_GAMES_PROMOTION_API,
  timeout: 5000,
});

export default {
  async getFreeBets(config, user) {
    const playerId = user.profile.id;
    const gameId = config.productId;
    const headers = {
      ...gamesHeaders.getNgsHeaders(config, user),
    };
    const { data } = await http.get(`/free-bets/active/players/${playerId}/games/${gameId}`, {
      headers,
    });

    return data;
  },
};
