const sounds = [
  {
    name: 'click',
    src: '/audio/gamesClick.mp3',
    type: 'sfx',
  },
  {
    name: 'clear',
    src: '/audio/gamesClear.mp3',
    type: 'sfx',
  },
  {
    name: 'deselect',
    src: '/audio/gamesDeselect.mp3',
    type: 'sfx',
  },
  {
    name: 'select',
    src: '/audio/gamesSelect.mp3',
    type: 'sfx',
  },
  {
    name: 'win',
    src: '/audio/gamesWin.mp3',
    type: 'sfx',
  },
];

export default {
  getAudio(category) {
    return sounds.filter(sound => sound.src.includes(category));
  },
};
