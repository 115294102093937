import { isNil } from 'lodash';

export default {
  balance: state => state.user.balance,
  isLoggedIn: state => state.user.auth && !isNil(state.user.auth.token),
  user: state => state.user,
  isTerminalUser: state => state.isTerminalUser,
  bonuses: state => state.user.bonuses,
  freeBets: state => state.user.freeBets,
};
