<template>
  <Button v-if="isLoginButtonActive"
          variation="positive"
          class="betslipButton login-payin"
          :type="type"
          :label="translations.general_login"
          @change="login()"
  ></Button>
  <Button v-else-if="hasTicketLockedBets"
          variation="positive"
          class="betslipButton"
          :type="type"
          :label="translations.general_accept"
          @change="removeInvalidBets()"
  ></Button>
  <Button v-else
          :type="type"
          class="login-payin"
          :class="{'free-bet': isBonusMode}"
          variation="positive"
          :label="payinButtonLabel"
          @change="payin()"
          :disabled="disabled"
  >
  </Button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from '../../Button';
import { eventBus } from '../../../utility';

export default {
  name: 'BetslipButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isTerminal: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  components: {
    Button,
  },
  data() {
    return {
      freeBetsCount: 0,
      payinButtonClicked: false,
    };
  },
  computed: {
    ...mapGetters('gamesBetslip', ['hasTicketLockedBets', 'ticket']),
    ...mapGetters(['translations', 'isBonusMode', 'isFreeBetMode', 'isAudioOn']),
    ...mapGetters('user', ['isLoggedIn', 'freeBets']),
    isLoginButtonActive() {
      return !this.isLoggedIn && !this.isTerminal;
    },
    payinButtonLabel() {
      if (this.isFreeBetMode) {
        return `${this.translations.general_free_bet} (${this.getFreeBetsCount()})`;
      }
      if (this.isBonusMode) {
        return this.translations.general_free_bet;
      }
      return this.translations.general_pay_in;
    },
  },
  methods: {
    ...mapActions('gamesBetslip', ['removeLockedBets']),
    login() {
      if (this.isAudioOn) {
        this.$AudioManager.play('click');
      }
      eventBus.$emit('LoginRequired');
    },
    payin() {
      if (this.isAudioOn) {
        this.$AudioManager.play('click');
      }
      eventBus.$emit('TicketPayin');
      this.payinButtonClicked = true;
    },
    removeInvalidBets() {
      if (this.isAudioOn) {
        this.$AudioManager.play('click');
      }
      eventBus.$emit('RemoveInvalidBets');

      setTimeout(async () => {
        await this.removeLockedBets();
        eventBus.$emit('formatBetslipInputValue');
      }, 300);
    },
    getFreeBetsCount() {
      // freeBetsCount - temporary saved free bets count
      if (this.freeBetsCount && Number(this.freeBetsCount) < Number(this.freeBets.count)) {
        if (this.payinButtonClicked) {
          return this.freeBetsCount;
        }
        this.freeBetsCount = Number(this.freeBets.count);
      }
      this.freeBetsCount = Number(this.freeBets.count) - Number(this.ticket.length);
      this.payinButtonClicked = false;
      return this.freeBetsCount;
    },
  },
  watch: {
    // watcher added because user could be awarded with new a batch of freeBets
    // in that case we don't have gcm message
    freeBets(obj) {
      if (obj.count) {
        this.freeBetsCount = Number(obj.count);
      }
    },
  },
};
</script>
