var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "stats-wrapper",
      class: [_vm.channelType, _vm.activeGameLayout.toLowerCase()],
    },
    [
      _c("div", { staticClass: "toggle-bet-stats" }, [
        _vm.bets["7"].value
          ? _c(
              "div",
              { staticClass: "bet-stats" },
              [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    attrs: { title: _vm.bets["7"].name },
                  },
                  [_vm._v(" " + _vm._s(_vm.bets["7"].name) + " ")]
                ),
                _c("div", { staticClass: "subtitle" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.translations.general_first_ball)),
                  ]),
                ]),
                _c(
                  "vue-circle",
                  {
                    ref: "firstBallOddEven",
                    attrs: {
                      progress: Number(_vm.stats.firstBallOddEven.even),
                      size: _vm.circleSize,
                      reverse: false,
                      "line-cap": "square",
                      fill: _vm.fill,
                      "empty-fill": "#d1d1d1",
                      "animation-start-value": 0.0,
                      "start-angle": -1.57,
                      "insert-mode": "append",
                      thickness: 3,
                      "show-percent": true,
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.translations.general_even))])]
                ),
                _c("div", { staticClass: "indicators" }, [
                  _c("div", { staticClass: "odd" }, [
                    _c("span", [_vm._v(_vm._s(_vm.translations.general_odd))]),
                  ]),
                  _c("div", { staticClass: "even" }, [
                    _c("span", [_vm._v(_vm._s(_vm.translations.general_even))]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.bets["8"].value
          ? _c(
              "div",
              { staticClass: "bet-stats" },
              [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    attrs: { title: _vm.bets["8"].name },
                  },
                  [_vm._v(" " + _vm._s(_vm.bets["8"].name) + " ")]
                ),
                _c("div", { staticClass: "subtitle" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.translations.general_first_ball)),
                  ]),
                ]),
                _c(
                  "vue-circle",
                  {
                    ref: "firstBallLowHigh",
                    staticClass: "circle",
                    attrs: {
                      progress: Number(_vm.stats.firstBallLowHigh.high),
                      size: _vm.circleSize,
                      reverse: false,
                      "line-cap": "square",
                      fill: _vm.fill,
                      "empty-fill": "#d1d1d1",
                      "animation-start-value": 0.0,
                      "start-angle": -1.57,
                      "insert-mode": "append",
                      thickness: 3,
                      "show-percent": true,
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.translations.general_over))])]
                ),
                _c("div", { staticClass: "indicators" }, [
                  _c("div", { staticClass: "odd" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.translations.general_under)),
                    ]),
                  ]),
                  _c("div", { staticClass: "even" }, [
                    _c("span", [_vm._v(_vm._s(_vm.translations.general_over))]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.bets["11"].value
          ? _c(
              "div",
              { staticClass: "bet-stats" },
              [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    attrs: { title: _vm.bets["11"].name },
                  },
                  [_vm._v(" " + _vm._s(_vm.bets["11"].name) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "subtitle" },
                  [
                    _c("LocalizedLabel", {
                      attrs: { translation: "preballsLabel" },
                    }),
                  ],
                  1
                ),
                _c(
                  "vue-circle",
                  {
                    ref: "preballsMoreOddEven",
                    attrs: {
                      progress: Number(_vm.stats.preballsMoreOddEven.even),
                      size: _vm.circleSize,
                      reverse: false,
                      "line-cap": "square",
                      fill: _vm.fill,
                      "empty-fill": "#d1d1d1",
                      "animation-start-value": 0.0,
                      "start-angle": -1.57,
                      "insert-mode": "append",
                      thickness: 3,
                      "show-percent": true,
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.translations.general_even))])]
                ),
                _c("div", { staticClass: "indicators" }, [
                  _c("div", { staticClass: "odd" }, [
                    _c("span", [_vm._v(_vm._s(_vm.translations.general_odd))]),
                  ]),
                  _c("div", { staticClass: "even" }, [
                    _c("span", [_vm._v(_vm._s(_vm.translations.general_even))]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.bets["6"].value
          ? _c(
              "div",
              { staticClass: "bet-stats" },
              [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    attrs: { title: _vm.bets["6"].name },
                  },
                  [_vm._v(" " + _vm._s(_vm.bets["6"].name) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "subtitle" },
                  [
                    _c("LocalizedLabel", {
                      attrs: { translation: "preballsSumLabel" },
                    }),
                  ],
                  1
                ),
                _c(
                  "vue-circle",
                  {
                    ref: "preballsSumLowHigh",
                    attrs: {
                      progress: Number(_vm.stats.preballsSumLowHigh.high),
                      size: _vm.circleSize,
                      reverse: false,
                      "line-cap": "square",
                      fill: _vm.fill,
                      "empty-fill": "#d1d1d1",
                      "animation-start-value": 0.0,
                      "start-angle": -1.57,
                      "insert-mode": "append",
                      thickness: 3,
                      "show-percent": true,
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.translations.general_over))])]
                ),
                _c("div", { staticClass: "indicators" }, [
                  _c("div", { staticClass: "odd" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.translations.general_under)),
                    ]),
                  ]),
                  _c("div", { staticClass: "even" }, [
                    _c("span", [_vm._v(_vm._s(_vm.translations.general_over))]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "number-bet-stats" }, [
        _c("div", { staticClass: "drawn-numbers" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.translations.general_hot_numbers))]),
          ]),
          _c(
            "div",
            { staticClass: "balls" },
            _vm._l(_vm.mostDrawnNumbers, function (value, key) {
              return _c("div", { key: key }, [
                _c(
                  "div",
                  [
                    _c("BingoGridButton", {
                      attrs: {
                        variation: "circle",
                        color: _vm.bingoColors[_vm.getBallColor(value.ball)],
                        active: true,
                        label: value.ball,
                      },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "count" }, [
                  _vm._v(" " + _vm._s(value.count) + " "),
                ]),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "drawn-numbers" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.translations.general_cold_numbers))]),
          ]),
          _c(
            "div",
            { staticClass: "balls" },
            _vm._l(_vm.leastDrawnNumbers, function (value, key) {
              return _c("div", { key: key }, [
                _c(
                  "div",
                  [
                    _c("BingoGridButton", {
                      attrs: {
                        variation: "circle",
                        color: _vm.bingoColors[_vm.getBallColor(value.ball)],
                        active: true,
                        label: value.ball,
                      },
                    }),
                  ],
                  1
                ),
                _c("span", { staticClass: "count" }, [
                  _vm._v(" " + _vm._s(value.count) + " "),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "color-bet-stats" }, [
        _c("div", { staticClass: "bet-stats" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.translations.general_color_frequency) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "subtitle" }, [
            _c("span", [_vm._v(_vm._s(_vm.translations.firstBall))]),
          ]),
          _c(
            "div",
            { staticClass: "colors" },
            _vm._l(_vm.firstBallColor, function (value, key, index) {
              return _c("div", { key: index, staticClass: "color" }, [
                _c("div", { staticClass: "color-title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.translations[`luckysix_color_${index}`])),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "card", class: `color-group-${index}` },
                  [
                    _c("ColorCheckbox", {
                      attrs: { color: _vm.bingoColors[index] },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "color-count" }, [
                  _vm._v(_vm._s(value)),
                ]),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }