export default {
  user: {
    auth: {},
    balance: 0,
    profile: {},
    bonuses: [],
    freeBets: {
      count: 0,
      betStake: 0,
    },
    sessionToken: null,
  },
  isLoggedIn: false,
  isTerminalUser: false,
};
