var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "draw-details-wrapper" },
    [
      _c("Tabs", {
        attrs: { tabs: _vm.enabledTabs, activeTab: _vm.activeComponent },
        on: { change: _vm.updateTab },
      }),
      _c(
        "div",
        { staticClass: "draw-details-body" },
        [_c(_vm.activeComponent.component, { tag: "component" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }