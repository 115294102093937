/* eslint no-param-reassign: ["error", { "props": false }] */

import {
  findIndex,
  each,
  assign,
  sortBy,
} from 'lodash';
import { helpers } from '@nsftx/games-sdk-js/src/utility/index';
import types from './mutationTypes';

const betStructure = {
  market: '',
  outcome: [],
  odd: null,
  roundNumber: null,
};

export default {
  // New structure
  [types.SET_CONFIG](state, config) {
    state.config = config;
  },
  [types.UPDATE_CONFIG](state, property) {
    const key = Object.keys(property)[0];
    const value = Object.values(property)[0];
    state.config[key] = value;
  },
  [types.SET_NEXT_GEN_CONFIG](state, config) {
    state.nextGenConfig = config.settings;
    state.igniteInstance = config.ignite;
  },
  [types.SET_GAME_STATE](state, gameState) {
    state.gameState = gameState;
  },
  [types.SET_APPLICATION_MODE](state, mode) {
    state.appMode = mode;
  },
  [types.UPDATE_GAME_STATE](state, gameState) {
    assign(state.gameState, gameState);
    state.currentDrawnBall = gameState.ball;
  },
  [types.SET_DEVICE_TYPE](state, device) {
    state.deviceTypeLayout = device;
  },
  [types.TOGGLE_BET_NUMBERS](state, betNumbers) {
    state.selectedBetNumbers = betNumbers;
    let combinations = null;
    const isValidBet = betNumbers.length >= state.minBallSelection;
    const { bets } = state.config;
    const { translations } = state.translations;
    const sortedBetNumbers = sortBy(betNumbers, (number) => number.value);
    const selectedNumbers = sortedBetNumbers.map((item) => item.value).join(' ');

    if (isValidBet) {
      combinations = helpers.getCombinations(betNumbers.length, 6);
    }

    state.numbersBet = {
      ...betStructure,
      id: betNumbers.length - 6,
      market: betNumbers.length > 6 ? `${translations.general_system} 6/${betNumbers.length}` : bets[0].name,
      outcome: selectedNumbers,
      cleanOutcome: betNumbers.map(({ value }) => value),
      valid: isValidBet,
      roundNumber: state.roundId,
      combinations,
    };
    state.selectedBet = state.numbersBet.outcome.length ? state.numbersBet : {};
  },
  [types.TOGGLE_COLORSS](state, colorGroup) {
    const colorKey = findIndex(state.bingoColors, (color) => color === colorGroup.color);
    const { translations } = state.translations;
    state.selectedColor = colorGroup;
    state.colorBet = {
      ...betStructure,
      ...colorGroup,
      category: 'Standard',
      market: translations[`luckysix_color_${colorKey}`],
      outcome: state.selectedBetNumbers.map((item) => item.value).join(' '),
      cleanOutcome: state.selectedBetNumbers.map(({ value }) => value),
      roundNumber: state.roundId,
      valid: colorGroup.checked,
    };
    state.selectedBet = colorGroup.checked ? state.colorBet : {};
  },
  [types.TOGGLE_PREBALL_BET](state, active) {
    const market = state.selectedBetNumbers[0];
    const { translations } = state.translations;
    const odd = Number(state.config.bets[10].odds);
    state.preballBet = {
      ...betStructure,
      category: translations.general_special,
      market: translations.general_preball,
      outcome: market ? String(market.value) : '',
      odds: odd,
      cleanOutcome: market ? String(market.value) : '',
      active,
      roundNumber: state.roundId,
      valid: !!active && !!market,
      id: 10,
      type: 10,
    };
    state.selectedBet = state.preballBet;
  },
  [types.TOGGLE_SPECIAL_BETS](state, { specialBet, outcomeIndex }) {
    const { translations } = state.translations;
    if (specialBet) {
      const odd = specialBet.odd || specialBet.odds;
      state.selectedSpecialBet = {
        ...betStructure,
        category: translations.general_special,
        id: specialBet.id,
        type: specialBet.id,
        market: specialBet.name,
        outcome: specialBet.outcomes ? String(specialBet.outcomes[outcomeIndex]) : '',
        odds: odd,
        cleanOutcome: specialBet.outcomes ? String(specialBet.outcomes[outcomeIndex]) : '',
        outcomeIndex,
        roundNumber: state.roundId,
        valid: !!specialBet.outcomes,
      };
      state.selectedBet = state.selectedSpecialBet.outcome.length ? state.selectedSpecialBet : {};
    }
  },
  [types.TOGGLE_FIRST_BALL_COLOR](state, { bet, outcome }) {
    const { translations } = state.translations;
    const isValidBet = outcome.length < 3 || (outcome.length > 3 && outcome.length === 4);

    const calcOutcome = () => {
      let sum = 0;
      each(outcome, (value) => {
        const idx = findIndex(bet.outcomes, (o) => o === value);
        let k = parseInt(idx, 10);
        k = (k === 7) ? 0 : k + 1;
        sum += 2 ** k;
      });
      return sum;
    };

    const odd = Number(bet.odds[outcome.length - 1]);
    state.firstBallColorBet = {
      ...betStructure,
      category: translations.general_special,
      id: bet.id,
      type: bet.id,
      market: bet.name,
      outcome: outcome.join(' '),
      odds: odd,
      cleanOutcome: outcome,
      outcomeIndex: calcOutcome(),
      roundNumber: state.roundId,
      valid: isValidBet,
    };
    state.selectedBet = state.firstBallColorBet;
    if (!state.firstBallColorBet.outcome.length) state.selectedBet = {};
  },
  [types.RESET_SELECTED_BET_NUMBERS](state) {
    state.selectedBetNumbers = [];
  },
  [types.RESET_PREBALL_BET](state) {
    state.preballBet = {
      market: '',
      outcome: 'general_preball',
      odd: 1,
    };
  },
  [types.RESET_SELECTED_COLOR](state) {
    state.selectedColor = {};
  },
  [types.RESET_SPECIALS](state) {
    state.selectedSpecialBet = {};
  },
  [types.RESET_FIRST_BALL_COLOR](state) {
    state.firstBallColorBet = {};
  },
  [types.CLEAR_SELECTED_BET](state) {
    state.selectedBet = {};
  },
  [types.TOGGLE_MODAL_INFO](state) {
    state.showInfoModal = !state.showInfoModal;
  },
  [types.UPDATE_ROUND](state, roundId) {
    state.roundId = roundId;
  },
  [types.BETTING_DISABLE](state, disable) {
    state.isBettingDisabled = disable;
  },
  [types.SET_PRODUCT_STATS](state, statsData) {
    state.stats = {};
    state.stats = statsData;
  },
  [types.SET_PRODUCT_RESULTS](state, data) {
    state.resultsData = [];
    state.resultsData = data;
  },
  [types.TOGGLE_NEW_FEATURES](state) {
    state.showNewFeatures = !state.showNewFeatures;
  },
  [types.SET_THIRD_PARTY_TOKEN](state, token) {
    state.thirdPartyToken = token;
  },
  [types.SET_IS_MOBILE](state, isMobile) {
    state.isMobile = isMobile;
  },
  [types.SET_IS_TOUCH_DEVICE](state, isTouchDevice) {
    state.isTouchDevice = isTouchDevice;
  },
  [types.SET_IS_BETSLIP_OPEN](state, isBetslipOpen) {
    state.isBetslipOpen = isBetslipOpen;
  },
  [types.SET_JACKPOT_STATE](state, jackpotState) {
    state.jackpotState = jackpotState;
  },
  [types.TOGGLE_BONUS_MODAL](state, show) {
    state.showLuckyLoserModal = show;
  },
  [types.TOGGLE_FREE_BET_MODAL](state, show) {
    state.showFreeBetModal = show;
  },
  [types.TOGGLE_LUCKY_LOSER_MODAL](state, show) {
    state.showLuckyLoserModal = show;
  },
  [types.SET_GAME_CHANNEL_TYPE](state, channelType) {
    state.gameChannelType = channelType;
  },
  [types.SET_IS_TERMINAL](state, payload) {
    state.isTerminal = payload;
  },
  [types.SET_COUNTDOWN_DATA](state, data) {
    state.countdownData = data;
  },
  [types.SET_TERMINAL_USER_DATA](state, payload) {
    state.terminalUserData = payload;
  },
  [types.SET_ACTIVE_TERMINAL_LAYOUT](state, layout) {
    state.activeTerminalLayout = layout;
  },
  [types.SET_TICKET_HISTORY_TERMINAL](state, payload) {
    state.ticketHistoryTerminal = payload.message;
  },
  [types.SET_TICKET_DETAILS_DATA](state, payload) {
    state.ticketDetailsData = payload;
  },
  [types.SET_LOADED](state, data) {
    state.loaded = data;
  },
  [types.SET_TERMINAL_MOUSE_CURSOR_ENABLED](state, isEnabled) {
    state.terminalMouseCursorEnabled = isEnabled;
  },
  [types.SET_ACTIVE_COMPONENT](state, activeComponent) {
    state.activeComponent = activeComponent;
  },
  [types.SET_TICKET_TO_REBET](state, ticketToRebet) {
    state.ticketToRebet = ticketToRebet;
  },
  [types.SET_USER_IS_OPERATOR](state, isOperator) {
    state.userIsOperator = isOperator;
  },
  [types.SET_VISUALIZATION_LOADED](state, isLoaded) {
    state.isVisualizationLoaded = isLoaded;
  },
};
