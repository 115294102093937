var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "home",
      class: [
        _vm.gameChannelType.toLowerCase(),
        {
          "non-betting-area":
            _vm.activeTerminalLayout !== "Terminal" &&
            _vm.gameChannelType !== "Web",
        },
      ],
    },
    [_c(_vm.channelType, { tag: "component" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }