export default {
  SET_CONFIG: 'SET_CONFIG',
  SET_GAME_STATE: 'SET_GAME_STATE',
  SET_APPLICATION_MODE: 'SET_APPLICATION_MODE',
  UPDATE_GAME_STATE: 'UPDATE_GAME_STATE',
  SET_DEVICE_TYPE: 'SET_DEVICE_TYPE',
  TOGGLE_BET_NUMBERS: 'TOGGLE_BET_NUMBERS',
  TOGGLE_COLORSS: 'TOGGLE_COLORSS',
  TOGGLE_PREBALL_BET: 'TOGGLE_PREBALL_BET',
  TOGGLE_SPECIAL_BETS: 'TOGGLE_SPECIAL_BETS',
  TOGGLE_FIRST_BALL_COLOR: 'TOGGLE_FIRST_BALL_COLOR',
  RESET_SELECTED_BET_NUMBERS: 'RESET_SELECTED_BET_NUMBERS',
  RESET_PREBALL_BET: 'RESET_PREBALL_BET',
  RESET_SELECTED_COLOR: 'RESET_SELECTED_COLOR',
  RESET_SPECIALS: 'RESET_SPECIALS',
  RESET_FIRST_BALL_COLOR: 'RESET_FIRST_BALL_COLOR',
  TOGGLE_MODAL_INFO: 'TOGGLE_MODAL_INFO',
  CLEAR_SELECTED_BET: 'CLEAR_SELECTED_BET',
  BETTING_DISABLE: 'BETTING_DISABLE',
  SET_PRODUCT_STATS: 'SET_PRODUCT_STATS',
  SET_PRODUCT_RESULTS: 'SET_PRODUCT_RESULTS',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  TOGGLE_NEW_FEATURES: 'TOGGLE_NEW_FEATURES',
  SET_THIRD_PARTY_TOKEN: 'SET_THIRD_PARTY_TOKEN',
  UPDATE_ROUND: 'UPDATE_ROUND',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_IS_TOUCH_DEVICE: 'SET_IS_TOUCH_DEVICE',
  SET_IS_BETSLIP_OPEN: 'SET_IS_BETSLIP_OPEN',
  SET_JACKPOT_STATE: 'SET_JACKPOT_STATE',
  TOGGLE_BONUS_MODAL: 'TOGGLE_BONUS_MODAL',
  CHECK_IS_LUCKY_LOSER_MODE: 'CHECK_IS_LUCKY_LOSER_MODE',
  TOGGLE_FREE_BET_MODAL: 'TOGGLE_FREE_BET_MODAL',
  TOGGLE_LUCKY_LOSER_MODAL: 'TOGGLE_LUCKY_LOSER_MODAL',
  SET_GAME_CHANNEL_TYPE: 'SET_GAME_CHANNEL_TYPE',
  SET_IS_TERMINAL: 'SET_IS_TERMINAL',
  SET_COUNTDOWN_DATA: 'SET_COUNTDOWN_DATA',
  SET_TERMINAL_USER_DATA: 'SET_TERMINAL_USER_DATA',
  SET_ACTIVE_TERMINAL_LAYOUT: 'SET_ACTIVE_TERMINAL_LAYOUT',
  SET_TICKET_HISTORY_TERMINAL: 'SET_TICKET_HISTORY_TERMINAL',
  SET_TICKET_DETAILS_DATA: 'SET_TICKET_DETAILS_DATA',
  SET_LOADED: 'SET_LOADED',
  SET_TERMINAL_MOUSE_CURSOR_ENABLED: 'SET_TERMINAL_MOUSE_CURSOR_ENABLED',
  SET_ACTIVE_COMPONENT: 'SET_ACTIVE_COMPONENT',
  SET_TICKET_TO_REBET: 'SET_TICKET_TO_REBET',
  SET_USER_IS_OPERATOR: 'SET_USER_IS_OPERATOR',
  SET_PRODUCT_UUID: 'SET_PRODUCT_UUID',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  SET_NEXT_GEN_CONFIG: 'SET_NEXT_GEN_CONFIG',
  SET_VISUALIZATION_LOADED: 'SET_VISUALIZATION_LOADED',
};
