var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisualizationLoaded,
          expression: "isVisualizationLoaded",
        },
      ],
      ref: "badge",
      staticClass: "boost-manager",
    },
    [
      _vm.isMobile
        ? _c("div", { attrs: { id: "boostBadgeContainer" } })
        : _vm._e(),
      _c("div", { attrs: { id: "boostContainer" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }