var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "app",
      staticClass: "app",
      class: [_vm.theme, _vm.isCursorHidden],
      attrs: { id: "app" },
    },
    [
      _c("router-view"),
      _c("NewFeatures"),
      _vm.userProfile.profile.id ? _c("TicketsHistory") : _vm._e(),
      _vm.ticketCanceled
        ? _c(
            "Modal",
            {
              ref: "modal",
              staticClass: "cancel-ticket-confirm",
              attrs: { showFooter: "", htmlId: "cancel-confirm-modal" },
              on: {
                closeModal: _vm.closeCancelTicketModal,
                closeModalOutside: function ($event) {
                  return _vm.closeCancelTicketModal("outside")
                },
              },
            },
            [
              _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(" " + _vm._s(_vm.translations.general_info) + " "),
              ]),
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _vm._v(" " + _vm._s(_vm.ticketCanceledMessage) + " "),
                _c("div", { staticClass: "modal-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: { click: _vm.closeCancelTicketModal },
                    },
                    [_vm._v(" " + _vm._s(_vm.translations.ok) + " ")]
                  ),
                ]),
              ]),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: { click: _vm.closeCancelTicketModal },
                  },
                  [_vm._v(" " + _vm._s(_vm.translations.ok) + " ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showLuckyLoserModal
        ? _c(
            "Modal",
            {
              ref: "modal",
              staticClass: "lucky-loser-modal",
              attrs: { width: "344px", height: "348px", showFooter: "" },
              on: {
                closeModal: function ($event) {
                  return _vm.claimBonuses()
                },
                closeModalOutside: function ($event) {
                  return _vm.claimBonuses("outside")
                },
              },
            },
            [
              _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _c("div", { staticClass: "body-message-box" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.translations.general_free_bet)),
                  ]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.translations.general_congratulation.toUpperCase()
                      )
                    ),
                  ]),
                  _c("span", { staticClass: "subtitle-2" }, [
                    _vm._v(_vm._s(_vm.translations.general_claim_ticket)),
                  ]),
                ]),
                _c("div", { staticClass: "action" }, [
                  _c(
                    "button",
                    { staticClass: "button", on: { click: _vm.claimBonuses } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.translations.general_claim.toUpperCase()) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showFreeBetModal
        ? _c(
            "Modal",
            {
              ref: "modal",
              staticClass: "free-bet-modal",
              class: [{ mobile: _vm.isMobile }, { desktop: _vm.isDesktop }],
              attrs: { width: "344px", height: "348px", showFooter: "" },
              on: {
                closeModal: function ($event) {
                  return _vm.claimFreeBets()
                },
                closeModalOutside: function ($event) {
                  return _vm.claimFreeBets("outside")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "body", attrs: { slot: "body" }, slot: "body" },
                [
                  _c("img", {
                    attrs: {
                      src: require("./assets/free-bet-star.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "body-message-box" }, [
                    _c("div", { staticClass: "pre-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.translations.general_free_bet_pre_title.toUpperCase()
                        )
                      ),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.translations.general_free_bet)),
                    ]),
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.translations.general_congratulation.toUpperCase()
                        )
                      ),
                    ]),
                    _c("span", { staticClass: "subtitle-2" }, [
                      _vm._v(_vm._s(_vm.translations.general_claim_ticket)),
                    ]),
                  ]),
                  _c("div", { staticClass: "action" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        on: { click: _vm.claimFreeBets },
                      },
                      [_vm._v(" " + _vm._s(_vm.claimFreeBetsLabel) + " ")]
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("LandscapeNotification"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }