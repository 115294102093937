export default {
  clientApp: state => state.clientApp,
  config: state => state.config,
  isFullScreen: state => state.isFullScreen,
  streamUuid: state => state.streamUuid,
  numbersFormat: state => state.numbersFormat,
  isBalanceLowerThanPayment: (state, getters, rootState, rootGetters) => rootGetters['user/balance'] < rootGetters['betslip/payment'],
  isAudioOn: state => state.isAudioOn,
  isFreeBetMode: (state, rootGetters) => rootGetters['user/freeBets']?.count > 0,
  isBonusMode: (state, rootGetters) => (rootGetters['user/bonuses']
    && rootGetters['user/bonuses'].length > 0)
    && (Number(rootGetters['gamesBetslip/totalPayment']) === Number(rootGetters['user/bonuses'][0].freeBetAmount))
    && !state.isFreeBetMode,
  isMobile: state => state.isMobile,
  isTablet: state => state.isTablet,
  isDesktop: state => state.isDesktop,
  manualBalanceUpdate: state => state.manualBalanceUpdate,
  manualBalanceIntervalValue: state => state.manualBalanceIntervalValue,
  oddType: state => state.oddType,
};
