import { httpLastTickets } from './http';
import gameHeaders from './headers';

export default {
  getEndpoint(platformName) {
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const path = `VUE_APP_${platform}_TICKETS_HISTORY_PATH`;
    return process.env[path];
  },
  getTicketsHistory(config, user, { dateFrom, dateTo }) {
    const { platformName, productName } = config;
    let url = this.getEndpoint(platformName);
    if (platformName === 'nextGen') url = this.interpolateUrl(url, user.profile);
    const params = {
      seven: {
        count: 1000,
        id_language: config.locale,
        product: productName.split(' ').join(''),
        timeFrom: dateFrom,
        timeTo: dateTo,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      playtech: {
        product: productName.split(' ').join(''),
        player: user.profile.username,
        cpvUuid: config.productId,
        startDate: dateFrom,
        endDate: dateTo,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      nextGen: {
        count: 1000,
        id_language: config.locale,
        product: productName.split(' ').join(''),
        timeFrom: dateFrom,
        timeTo: dateTo,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    const headers = {
      ...gameHeaders.getAuthHeader(user, config),
      ...gameHeaders.getNgsHeaders(config, user),
    };

    return httpLastTickets(config).get(url, { params: params[platformName], headers });
  },
  // USED TO POPULATE VALUES INSIDE {} curly brackets in url
  interpolateUrl(url, config) {
    return url.replace(/{([^}]+)}/g, (match, key) => (Object.prototype.hasOwnProperty.call(config, key) ? config[key] : match));
  },
};
