import { auth, freeBets, bonus } from '../../../api';
import types from './mutationTypes';
import notificationTypes from '../notifications/notificationTypes';

let manualBalanceUpdateTimeout = null;

export default {
  updateUser({
    commit,
    getters,
    dispatch,
    rootGetters,
  }, payload) {
    commit(types.UPDATE_USER, payload);

    if (getters.isLoggedIn) {
      dispatch('notifications/clearNotification', {
        type: notificationTypes.USER_LOGIN,
      }, { root: true });
    } else {
      dispatch('notifications/setNotification', {
        type: notificationTypes.USER_LOGIN,
        message: rootGetters.translations.general_player_login,
        status: 'negative',
        notificationTimeout: true,
      }, { root: true });
    }
  },
  // we should call this only if 3rd party integration don't send balance value
  getUserBalance({ rootGetters, dispatch }) {
    dispatch('clearManualBalanceTimeout');
    try {
      const { token } = rootGetters['user/user'].auth;
      const { config } = rootGetters;
      auth.getBalance(config, token).then((response) => {
        if (response) {
          dispatch('setUserBalance', response.balance, { root: true });
          dispatch('setManualBalanceTimeout');
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error: ', error);
    }
  },
  async getUserBonuses({ dispatch, rootGetters }) {
    try {
      const user = rootGetters['user/user'];
      const { config } = rootGetters;
      const data = await bonus.getBonuses(config, user);
      dispatch('setUserBonuses', data);
    } catch (error) {
      dispatch('notifications/setNotification', {
        message: rootGetters.translations.general_bonus_api_error,
        status: 'negative',
        notificationTimeout: true,
      }, { root: true });
    }
  },
  async getUserFreeBets({ dispatch, rootGetters }) {
    try {
      const { config } = rootGetters;
      const user = rootGetters['user/user'];
      const data = await freeBets.getFreeBets(config, user);

      if (data) {
        dispatch('setUserFreeBets', data);
      }
    } catch (error) {
      dispatch('notifications/setNotification', {
        message: rootGetters.translations.general_free_bet_api_error,
        status: 'negative',
        notificationTimeout: true,
      }, { root: true });
    }
  },
  setManualBalanceTimeout({ rootGetters, dispatch }) {
    const { manualBalanceTimeoutValue } = rootGetters.config.ui.config;
    manualBalanceUpdateTimeout = setTimeout(() => {
      dispatch('getUserBalance');
    }, manualBalanceTimeoutValue);
  },
  clearManualBalanceTimeout() {
    clearTimeout(manualBalanceUpdateTimeout);
  },
  setUserBalance({ commit }, payload) {
    commit(types.SET_USER_BALANCE, payload);
  },
  setTerminalUser({ commit }, payload) {
    commit(types.SET_TERMINAL_USER, payload);
  },
  setUserBonuses({ commit }, payload) {
    commit(types.SET_USER_BONUSES, payload);
  },
  setUserFreeBets({ commit }, bets) {
    commit(types.GIVE_THE_USER_FREE_BETS, bets);
  },
  setUserSessionToken({ commit }, token) {
    commit(types.SET_USER_SESSION_TOKEN, token);
  },
};
