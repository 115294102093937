import { uuid } from 'vue-uuid';
import { capitalize } from 'lodash';
import { httpCancelTicket } from './http';
import gamesHeaders from './headers';

export default {
  getEndpoint(platformName) {
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const path = `VUE_APP_${platform}_TICKET_CANCEL`;
    return process.env[path];
  },
  getReqUuid() {
    return {
      requestUuid: uuid.v4(),
    };
  },
  createMeta(config, user, params) {
    const platform = config.platformName;
    const metaData = {
      seven: {
        product: config.productFqn,
        deliveryPlatform: capitalize(config.applicationName),
        cpvUuid: config.productId,
        paymentMethod: 'VirtualMoney',
        requestUuid: params.requestUuid,
        appDeviceUuid: '054e200d-fd54-4204-a742-71737ed9671d', // TODO
        sources: [
          {
            type: 'player',
            uuid: user.profile.id,
          },
          {
            type: 'productInstance',
            uuid: config.productId,
          },
        ],
      },
      nextGen: {
        requestUuid: params.requestUuid,
        deliveryPlatform: capitalize(config.applicationName),
        b2bVal: config.b2bVal,
        platformVal: config.platformVal,
        punterToken: user.auth.token,
        player: {
          firstname: user.profile.firstname,
          lastname: user.profile.lastname,
          username: user.profile.username,
          uuid: user.profile.id,
        },
      },
    };
    return metaData[platform];
  },
  cancel(config, user, id) {
    const { platformName } = config;
    const url = this.getEndpoint(platformName);
    const params = this.getReqUuid();
    const data = {
      metadata: this.createMeta(config, user, params),
      ticket: {
        id,
      },
    };

    const headers = {
      ...gamesHeaders.getAuthHeader(user, config),
      ...gamesHeaders.getPlatformHeaders(user, config),
    };

    return httpCancelTicket(config).delete(url, { headers, data });
  },
};
