import { Howl } from 'howler';

export default class AudioManager {
  sounds = [];

  constructor(sounds) {
    this.loadSounds(sounds);
  }

  loadSounds(sounds) {
    sounds.forEach((sound) => {
      const { src, ...remaining } = sound;
      const loadedSound = new Howl({
        src,
        ...remaining,
      });

      loadedSound.name = sound.name;
      loadedSound.type = sound.type;

      this.sounds.push(loadedSound);
    });
  }

  getSound(name) {
    return this.sounds.find(sound => sound.name === name);
  }

  play(name) {
    const sound = this.getSound(name);
    sound.play();
  }

  stop(name) {
    const sound = this.getSound(name);
    sound.stop();
  }

  isPlaying(name) {
    const sound = this.getSound(name);
    return sound.isPlaying();
  }
}
