import {
  range,
  findIndex,
  map,
  filter,
  every,
} from 'lodash';

export default {
  config: (state) => state.config,
  currentDrawnBall: (state) => state.currentDrawnBall,
  currentRound: (state) => state.gameState.eventId,
  gameState: (state) => state.gameState,
  deviceTypeLayout: (state) => state.deviceTypeLayout,
  gameChannelType: (state) => state.gameChannelType,
  isWebChannelType: (state) => state.gameChannelType === 'Web',
  activeGameLayout: (state) => state.gameLayout,
  isCompactLayout: (state) => state.gameLayout === 'compact',
  isExpandedLayout: (state) => state.gameLayout === 'expanded',
  minBallSelection: (state) => state.minBallSelection,
  maxBallSelection: (state) => state.maxBallSelection,
  maxOutcomeSelection: (state) => state.maxOutcomeSelection,
  edgeCaseSelection: (state) => state.edgeCaseSelection,
  bingoColors: (state) => state.bingoColors,
  selectedBetNumbers: (state) => state.selectedBetNumbers,
  selectedColor: (state) => state.selectedColor,
  numbersBet: (state) => state.numbersBet,
  getNumberColor: (state, getters) => (number) => {
    const colors = getters.bingoColors;
    return colors[(number - 1) % colors.length];
  },
  bingoNumbers: (state, getters) => {
    const numbers = range(state.minBingoNumber, state.maxBingoNumber + 1);
    return map(numbers, (number) => {
      const color = getters.getNumberColor(number);
      return {
        hex: color,
        value: number,
        isSelected: findIndex(getters.selectedBingoNumbers, (n) => n.number === number) > -1,
      };
    });
  },
  specials: (state, getters) => {
    const excludedSpecials = [0, 1, 2, 3, 4, 5, 9, 10];
    const { bets } = getters.config;

    return filter(bets, (bet) => bet.value && !excludedSpecials.includes(bet.id));
  },
  firstBallColor: (state) => state.config.bets[5],
  preballBet: (state) => state.preballBet,
  selectedSpecialBet: (state) => state.selectedSpecialBet,
  firstBallColorBet: (state) => state.firstBallColorBet,
  selectedBet: (state) => state.selectedBet,
  showInfoModal: (state) => state.showInfoModal,
  roundId: (state) => state.roundId,
  visualizationPlugin: (state) => state.visualizationPlugin,
  oddsType: (state) => state.oddsType,
  showBackButton: (state) => state.showBackButton,
  fullScreenEnabled: (state) => state.fullScreenEnabled,
  ticketCancelAllowed: (state) => state.ticketCancelAllowed,
  regulatoryMessageText: (state) => state.config.ui.config.regulatoryMessage,
  gcmApi: (state) => state.gcmApi,
  stats: (state) => state.stats,
  resultsData: (state) => state.resultsData,
  drawnBalls: (state) => state.drawnBalls,
  showModalStats: (state) => state.showModalStats,
  pickNumbers: (state) => state.pickNumbers,
  isBettingDisabled: (state) => state.isBettingDisabled,
  predefinedStakeValues: (state) => state.appConfig.company.predefinedStakes,
  isFullScreen: (state) => state.isFullScreen,
  showNewFeatures: (state) => state.showNewFeatures,
  showFeed: (state) => state.showFeed,
  thirdPartyToken: (state) => state.thirdPartyToken,
  integrationMode: (state) => state.appMode,
  isTouchDevice: (state) => state.isTouchDevice,
  isBetslipOpen: (state) => state.isBetslipOpen,
  jackpotState: (state) => state.jackpotState,
  isIsonis: (state) => state.isonisList.indexOf(state.config.taxAuthority) > -1,
  isBoostActive: (state) => state.config.ui.config?.boost?.enabled || false,
  showLuckyLoserModal: (state) => state.showLuckyLoserModal,
  showFreeBetModal: (state) => state.showFreeBetModal,
  showBonusModal: (state) => state.showBonusModal,
  isTerminal: (state) => state.isTerminal,
  countdownData: (state) => state.countdownData,
  systemBetsDisabled: (state) => {
    const { bets } = state.config;
    const systemBets = filter(bets, (bet) => bet.betType === 'System');
    return every(systemBets, ['value', false]);
  },
  activeTerminalLayout: (state) => state.activeTerminalLayout,
  ticketHistoryTerminal: (state) => state.ticketHistoryTerminal,
  terminalUserData: (state) => state.terminalUserData,
  ticketDetailsData: (state) => state.ticketDetailsData,
  userIsOperator: (state) => state.userIsOperator,
  loaded: (state) => state.loaded,
  combinationsBase: (state) => state.combinationsBase,
  isTerminalMouseCursorEnabled: (state) => state.terminalMouseCursorEnabled,
  activeComponent: (state) => state.activeComponent,
  ticketToRebet: (state) => state.ticketToRebet,
  ajsDisabled: (state) => state.config?.ui?.config?.ajsDisabled ?? false,
  isNextGen: (state) => state.config.platformName === 'nextGen',
  isVisualizationLoaded: (state) => state.isVisualizationLoaded,
  igniteInstance: (state) => state.igniteInstance,
};
