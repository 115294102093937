import { uuid } from 'vue-uuid';
import { map, capitalize } from 'lodash';
import { httpPayin } from './http';
import gamesHeaders from './headers';

export default {
  getParamsUuid() {
    return {
      requestUuid: uuid.v4(),
      deviceUuid: uuid.v4(),
    };
  },
  getTerminalParamsUuid() {
    return {
      requestUuid: uuid.v4(),
    };
  },
  createMeta(config, user, params) {
    const platform = config.platformName;
    const sources = [
      {
        type: 'player',
        uuid: user.profile.id,
      },
      {
        type: 'productInstance',
        uuid: config.productUuid ? config.productUuid : config.productId,
      },
    ];
    const metaData = {
      seven: {
        product: config.productFqn,
        deliveryPlatform: capitalize(config.applicationName),
        cpvUuid: config.productId,
        paymentMethod: 'VirtualMoney',
        requestUuid: params.requestUuid,
        appDeviceUuid: params.deviceUuid, // TODO
        sources,
      },
      playtech: {
        player: {
          firstname: '',
          lastname: '',
          username: user.profile.id || user.profile.username,
          uuid: user.profile.id || user.profile.username,
          isThirdParty: false,
        },
      },
      nextGen: {
        requestUuid: params.requestUuid,
        deliveryPlatform: capitalize(config.applicationName),
        b2bVal: config.b2bVal,
        platformVal: config.platformVal,
        punterToken: user.auth.token,
        player: {
          firstname: user.profile.firstname,
          lastname: user.profile.lastname,
          username: user.profile.username,
          uuid: user.profile.id,
        },
      },
    };
    return metaData[platform];
  },
  createTicket(betslip, formatTicketBets, user) {
    const {
      ticket,
      payment,
      ticketType,
      bonuses,
      providerBetslipId,
    } = betslip;
    return {
      bets: formatTicketBets(ticket),
      payin: payment,
      ticketComment: 'Prva Firma', // TODO
      ticketType, // TODO
      ticketOddsOptions: 2, // TODO,
      isFreeBet: !!user?.freeBets?.count || false,
      bonuses,
      providerBetslipId,
    };
  },
  formatTicketBets(tickets) {
    return map(tickets, ticket => ({
      type: ticket.outcomeId,
      value: [ticket.id],
    }));
  },
  payin(config, user, betslip, formatTicketBets = this.formatTicketBets, additionalInfo) {
    const headers = {
      ...gamesHeaders.getAuthHeader(user, config),
      ...gamesHeaders.getPlatformHeaders(user, config),
    };
    const platform = config.platformName.toUpperCase();
    const path = config.applicationName === 'Terminal'
      ? process.env.VUE_APP_TICKET_PAYIN_TERMINAL_PATH
      : process.env[`VUE_APP_${platform}_TICKET_PAYIN_PATH`];
    let params = config.applicationName === 'Terminal' ? this.getTerminalParamsUuid() : this.getParamsUuid();

    // We don't need this on Playtech platform
    if (platform !== 'SEVEN' && platform !== 'NEXTGEN') params = {};

    const ticket = this.createTicket(betslip, formatTicketBets, user);
    const data = {
      additionalInfo,
      metadata: config.applicationName === 'Terminal' ? null : this.createMeta(config, user, params),
      ticket,
    };
    if (config.applicationName === 'Terminal') {
      return data;
    }
    return httpPayin(config).post(path, data, { params, headers });
  },
  // When game passes ticket payload
  genericPayin(config, payload) {
    const { platformName } = config;

    const path = process.env.VUE_APP_TICKET_PAYIN_PATH;
    return httpPayin(platformName).post(path, payload);
  },
};
